.chatbot {
  font-family: monospace;
  border-radius: 10px;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  background: #f5f8fb;
  text-align: center;
  display: flex;
  flex-flow: column;
  width: 800px;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  background-image: url("https://as2.ftcdn.net/v2/jpg/01/45/62/21/1000_F_145622159_aZCjLhDiW6WU6tVwPkcU4buqyTHXWXyx.jpg"); /* Replace 'path/to/your/image.jpg' with the path to your image */
  background-size: cover;
  background-position: center;
  font-family: Arial, sans-serif; /* Example font family */
}
.chatsection {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.header {
  padding: 20px;

  text-align: center;
  color: rgb(0, 0, 0);
  max-height: 58px;
  font-size: 20px;
  font-weight: bold;
  background-color: #ebeff1;
}

.messages {
  width: 100%;
  height: 400px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: #333;
  padding: 10px 0px;
}

.message-container {
  width: 100%;
}

.bot-message {
  float: left;
  padding: 15px 20px;
  margin: 5px;
  border-radius: 20px 20px 20px 1px;
  background: #00aaa5;
  color: white;
  min-width: 40px;
}

.user-message {
  float: right;
  padding: 15px 10px;
  margin: 10px;
  border-radius: 20px 20px 1px 20px;
  background: #cccccc;
  color: black;
}

.input {
  position: relative;
}

.input > form > input[type="text"] {
  font-family: monospace;
  font-size: 16px;
  border: 0;
  border-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #eee;
  box-shadow: none;
  box-sizing: border-box;
  opacity: 1;
  outline: none;
  padding: 16px 52px 16px 10px;
  width: 100%;
}

.input > form > input:focus {
  outline: none;
}

.input > form > button {
  background-color: transparent;
  border: 0;
  border-bottom-right-radius: 10px;
  box-shadow: none;
  cursor: pointer;
  fill: #4a4a4a;
  opacity: 1;
  outline: none;
  padding: 14px 16px 12px 16px;
  position: absolute;
  right: 0;
  top: 0;
}

.navbar {
  background-color: #333;
  color: white;
  position: fixed;
  padding: 10px 20px;
  width: 100%;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}
